.electricity {
  max-width: 500px;
  min-height: 80vh;
  margin: 0 auto;
  display: flex;
  scale: 0.5;
  width: 100%;
  position: relative;
}

.electricity-svg {
  position: absolute;
  top: 18vh; 
  width: 90%;
  left: 50%;
  transform: translate(-50%);
}

.minus-diode,
.plus-diode {
  position: absolute;
  bottom: 0;
  height: 50vh;
  width: 2vh;
  background: #888;
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
  
  &:before {
    position: absolute;
    top: 5vh;
    bottom: 1vh;
    left: 1px;
    background: white;
    width: 1px;
    content: '';
    opacity: 0.5;
  }
}

.minus-diode {
  left: auto;
  right: 0;
}

.ring {
  position: absolute;
  top: 50%;
  width: 20vh;
  height: 2vh;
  background: #555;
  left: 50%;
  transform: translate(-50%);
  border-radius: 1vh;
  &.medium {
    width: 15vh;
    top: 35%;
  }
  &.small {
    width: 10vh;
    top: 10%;
  }
  &:before {
    background: white;
    height: 1px; 
    position: absolute;
    top: 0.5vh;
    left: 5%;
    content: '';
    width: 50%;
    opacity: 0.5
  }
}

.socket {
  position: absolute;
  background: #222;
  width: 2vh;
  top: 0;
  height: 1vh;
  z-index: 5;
  &.foot {
    transform: translate(-50%) rotate(-180deg);
    top: auto;
    bottom: 0;
    width: 10vh;
    height: 5vh;
    left: 50%;
  }
  &:before {
    content: '';
    border-bottom: 1vh solid transparent;
    border-right: 2vh solid #222;
    position: absolute;
    right: 100%;
  }
  &:after {
    content: '';
    border-bottom: 1vh solid transparent;
    border-left: 2vh solid #222;
    position: absolute;
    left: 100%;
  }
}

.ball {
  background: #666;
  width: 12vh;
  height: 12vh;
  bottom: 99%;
  border-radius: 50%;
  position: absolute;
  z-index: 15;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  &:before {
    content:'';
    border-radius: 50%;
    background: #eee;
    width: 2vh;
    height: 2vh;
    position: absolute;
    top: 2.5vh;
    right: 2.5vh;
    z-index: 10;
  }
  
  &:after {
    content:'';
    border-radius: 50%;
    background: #999;
    width: 12vh;
    height: 12vh;
    position: absolute;
    bottom: 0.5vh;
    left: 0.5vh;
  }
  
  .minus-diode & {
    &:before {
      right: auto;
      left: 2.5vh;
    }
    &:after {
      left: auto;
      right: 0.5vh;
    }
  }
  .shine {
    width: 1vh;
    height: 1vh;
    box-shadow: 0 0 10vh 3vh lightgreen;
    position: absolute;
    top: 50%;
    right: -1vh;
    z-index: 10;
    .minus-diode & {
      left: -1vh;
      right: auto;
    }
  }
}
